import React from 'react'
import { buildCTALink } from '../utilities'

export default function PricingRow(props) {
    const {
        hook,
        productHint,
        followUp,
        columns,
        ctaButton,
        ctaDemoButton,
    } = props

    const columnsEl = columns.map(({ header, points, ctaButton }, i) => {
        const pointsEl = points.map((point, j) => {
            return (
                <li
                    className={productHint}
                    key={`point-${i}-${j}`}
                    dangerouslySetInnerHTML={{ __html: point }}
                />
            )
        })

        let ctaButtonEl = buildCTALink(ctaButton, productHint)

        return (
            <div key={i} className="pricing-column">
                <strong className="pricing-column-header">
                    {header || ' '}
                </strong>
                <ul>{pointsEl}</ul>
                {ctaButtonEl}
            </div>
        )
    })


    const centeredButtonEl = ctaButton
        ? buildCTALink(ctaButton, productHint)
        : null


    const centeredDemoButtonEl = ctaDemoButton
        ? buildCTALink(ctaDemoButton, productHint, true)
        : null

    return (
        <div id="pricing" className="solution-row pricing-row col-12">
            <h2>{hook}</h2>
            <p className="follow-up">{followUp}</p>
            <div className="demo-button">{centeredDemoButtonEl}</div>
            <div className="pricing-table">
                <div className="pricing-table-list">
                    <div className={`pricing-columns ${productHint}`}>
                        {columnsEl}
                    </div>
                    {centeredButtonEl}
                </div>
            </div>
        </div>
    )
}
