import React from 'react'
import CNN from '../images/social-icons/cnn.png'
import Google from '../images/social-icons/google.png'
import Times from '../images/social-icons/times.png'
import TWC from '../images/social-icons/twc.png'
import Facebook from '../images/social-icons/fb-logo.png'
import InstaGram from '../images/social-icons/ig.png'

export default function Promoting() {
    return (
        <div id="promoting" className="promoting container">
            <div className="promoting__header">
                Where are we promoting your listings?
            </div>
            <div className="promoting__subhead">
                Your targeted audiences will see your ads across the web and on
                social platforms.
            </div>
            <div className="card_container">
                <div className="card">
                    <img alt="Facebook" src={Facebook} />
                    <div className="title">Facebook</div>
                </div>
                <div className="card">
                    <img alt="InstaGram" className="ig" src={InstaGram} />
                    <div className="title">Instagram</div>
                </div>
                <div className="card">
                    <div className="img-group">
                        <div className="img-wrapper">
                            <img src={Google} alt="Google" />
                        </div>
                        <div className="img-wrapper">
                            <img src={Times} alt="Times" />
                        </div>
                        <div className="img-wrapper">
                            <img src={CNN} alt="CNN" />
                        </div>
                        <div className="img-wrapper">
                            <img src={TWC} alt="TWC" />
                        </div>
                    </div>
                    <div className="title">Across thousands of websites</div>
                </div>
            </div>
        </div>
    )
}
