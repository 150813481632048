import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

export default function SupportRow(props) {
    const { hook, followUp, image } = props

    let followUpEl = null
    if (followUp) {
        followUpEl = (
            <p
                className="follow-up"
                dangerouslySetInnerHTML={{ __html: followUp }}
            />
        )
    }

    return (
        <div id={hook.toLowerCase().replace(/\s+/g, '-')}>
            <div className="solution-row support-row">
                <div className="support-row-image-container">
                    {image && <GatsbyImage image={image.src.childImageSharp.gatsbyImageData} />}
                </div>
                <div className="support-row-content">
                    <h2>{hook}</h2>
                    {followUpEl}
                </div>
            </div>
        </div>
    );
}
