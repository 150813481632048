import React, { Component } from 'react'

class RowGroup extends Component {
    render() {
        return (
            <div className={this.props.name}>
                <div className="row-group-content">{this.props.children}</div>
            </div>
        )
    }
}

export default RowGroup
