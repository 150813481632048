import React, { Component } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

export default class BoostCarousel extends Component {
    state = { step: 0 }

    nextStep = () => {
        if (this.state.step !== this.props.slides.length) {
            this.setState({ step: this.state.step + 1 })
        }
    }

    prevStep = () => {
        if (this.state.step !== 0) {
            this.setState({ step: this.state.step + 1 })
        }
    }

    goToStep = step => {
        this.setState({ step: step })
    }

    activeTab = step => {
        return step === this.state.step ? 'active' : ''
    }

    render() {
        const { slides } = this.props

        return (
            <div id="how-it-works" className="carousel container col-12">
                <div className="carousel img-container span-6-mx">
                    <GatsbyImage image={slides[this.state.step].src.childImageSharp.gatsbyImageData} />
                </div>
                <div className="carousel content span-6-mx">
                    <p className="carousel subheader">How does it work?</p>
                    <div className="carousel step-header">
                        {slides[this.state.step].title}
                    </div>
                    <div className="carousel body">
                        {slides[this.state.step].copy}
                    </div>
                    <div className="carousel steps">
                        {slides.map((step, i) => (
                            <div
                                key={i}
                                className={`carousel step ${this.activeTab(i)}`}
                                onClick={() => this.goToStep(i)}
                            >
                                <span className="circle"> {i + 1} </span>
                                {step.title}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
