import React from 'react'
import { graphql } from 'gatsby'

import '../styles/global.scss'

import Layout from '../components/Layout'
import SolutionNavigationRow from '../components/SolutionNavigationRow'
import MarketingRowNav from '../components/marketingRowNav'
import HeroRow from '../components/HeroRow'
import MarketingRow from '../components/MarketingRow'
import MarketingRowReversed from '../components/MarketingRowReversed'
import MarketingRowCentered from '../components/MarketingRowCentered'
import MarketingQuote from '../components/MarketingQuote'
import BoostCarousel from '../components/BoostCarousel'
import CallToAction from '../components/CallToAction'
import Promoting from '../components/Promoting'
import SupportRow from '../components/SupportRow'
import PricingRow from '../components/PricingRow'
import RowGroup from '../components/RowGroup'
import Integration from '../components/Integrations'
import FavoriteRelationships from '../components/favoriteRelationships'

const components = {
    SolutionNavigationRow: SolutionNavigationRow,
    HeroRow: HeroRow,
    MarketingRow: MarketingRow,
    MarketingRowNav,
    MarketingRowReversed: MarketingRowReversed,
    MarketingRowCentered: MarketingRowCentered,
    MarketingQuote: MarketingQuote,
    BoostCarousel: BoostCarousel,
    CallToAction: CallToAction,
    SupportRow,
    Promoting,
    PricingRow,
    Integration,
    FavoriteRelationships,
}

export default function CustomerProductPage(props) {
    const { data, location } = props
    const { customerProductsJson } = data
    const {
        name,
        rows,
        bodyClass,
        usePopUp,
        title,
        description,
        keywords,
    } = customerProductsJson

    const htmlRows = rows.reduce((acc, row, i) => {
        const RowComponent = components[row.component]

        let rowComponent = (
            <RowComponent
                key={i}
                type={name}
                location={location}
                {...row.props}
            />
        )

        // Logic to combine any successive rows with the same 'groupComponent'
        // as children components of an instance of that groupComponent
        if (row.groupComponent) {
            const [lastRow, ...rest] = [...acc].reverse()
            let childrenComponents = [rowComponent]

            // If this row belongs to the same group as the previous row
            // Combine them
            if (lastRow.props.type === row.groupComponent.name) {
                childrenComponents = [...lastRow.props.children, rowComponent]

                // Discard the lastRow, since we're creating a new RowGroup
                // below with those children combined
                acc = [...rest.reverse()]
            }

            rowComponent = (
                <RowGroup
                    key={`group-${i}`}
                    {...row.groupComponent}
                    type={row.groupComponent.name}
                >
                    {childrenComponents}
                </RowGroup>
            )
        }

        return [...acc, rowComponent]
    }, [])

    return (
        <Layout
            title={title}
            description={description}
            keywords={keywords}
            usePopUp={usePopUp}
            bodyClass={bodyClass}
        >
            {htmlRows}
        </Layout>
    )
}

export const pageQuery = graphql`query ($path: String!) {
  customerProductsJson(path: {eq: $path}) {
    name
    bodyClass
    title
    description
    keywords
    usePopUp
    rows {
      component
      groupComponent {
        name
      }
      props {
        navRow {
          hook
          title
        }
        productHint
        hook
        followUp
        copy1
        quote
        disclaimer
        source
        backgroundImage
        image {
          name
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        logos {
          name
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        ctaButton {
          text
          type
          href
        }
        ctaDemoButton {
          text
          type
          href
        }
        secondaryCta {
          text
          type
          url
        }
        links {
          text
          href
        }
        theme
        content
        action {
          text
          component
          props {
            href
            checked
          }
        }
        price1 {
          price
          period
          detail
        }
        price2 {
          price
          period
          detail
        }
        columns {
          header
          points
          ctaButton {
            text
            type
          }
        }
        slides {
          title
          copy
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        footerLines
        customers
      }
    }
  }
}
`
